// vendors
import React from 'react';

// components
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader/PageHeader';
import Heading from '../components/Heading/Heading';
import { UnderlinedButton } from '../components/Button';

const translations = [
  {
    locale: 'en',
    url: '/en/shipping-and-returns/',
  },
];

export default function LivraisonsRetoursPage() {
  return (
    <Layout translations={translations}>
      <article className="space-y-48">
        <PageHeader>
          Livraison <br />
          et retours
        </PageHeader>

        <div className="container">
          <section className="max-md:block grid grid-cols-[200px_1fr] gap-20">
            <Heading level={2} className="!mt-0">
              Livraison
            </Heading>

            <div>
              <Heading level={3} className="!mt-3 text-3xl">
                Combien de temps faut-il pour traiter ma commande?
              </Heading>
              <p>
                Toutes les commandes sont traitées dans un délai de{' '}
                <strong>2 à 4 jours ouvrables</strong> (hors week-end et jours
                fériés) après réception de votre e-mail de confirmation de
                commande. Vous recevrez une autre notification lorsque votre
                commande sera expédiée.
              </p>

              <Heading level={3} className="text-3xl">
                Combien coûtent les frais de livraison?
              </Heading>
              <p>
                Les frais de livraison sont calculés selon la taille et le poids
                de votre commande et affichés lors du processus d'achat avant
                l'étape de paiement de votre commande. Le transporteur (Poste
                Canada ou UPS) sera automatiquement sélectionné en fonction de
                votre adresse de livraison. Toutes les options de livraison
                peuvent différer ou ne pas être disponibles selon la destination
                finale (boîte postale, forces armées et territoires
                périphériques, etc.).
              </p>

              <Heading level={3} className="text-3xl">
                Quelles sont vos délais de livraison?
              </Heading>

              <p>
                Les délais de livraison varient selon le transporteur (Poste
                Canada ou UPS) et l'option de livraison sélectionnés. À noter
                que les délais de livraison du transporteur sont hors de
                contrôle de Guitares Pellerin et peuvent être plus longs que
                prévu, nottament lors de périodes plus achalandées durant
                l'année.
              </p>

              {/* <div className="max-xs:block grid grid-cols-3">
                <div>
                  <Heading level={4} className="text-2xl">
                    Canada
                  </Heading>
                  <p>
                    <strong>2 à 5 jours ouvrables</strong>
                  </p>
                </div>
                <div>
                  <Heading level={4} className="text-2xl">
                    États-Unis
                  </Heading>
                  <p>
                    <strong>4 à 6 jours ouvrables</strong>
                  </p>
                </div>
                <div>
                  <Heading level={4} className="text-2xl">
                    International
                  </Heading>
                  <p>
                    <strong>5 à 10 jours ouvrables</strong>
                  </p>
                </div>
              </div> */}

              <Heading level={3} className="text-3xl">
                Les droits et taxes d'importation sont-ils inclus dans vos prix?
              </Heading>
              <p>
                Les droits et taxes d'importation ne sont pas inclus et restent
                entiérement à votre charge. Votre commande peut entraîner des
                droits, frais et taxes d'importation à la livraison (TVA
                incluse). Ces frais sont déterminés par votre autorité douanière
                locale. Le paiement de ceux-ci est nécessaire pour libérer votre
                commande des douanes. Pour plus d'informations, veuillez
                consulter votre bureau de douane local.
              </p>

              <Heading level={3} className="text-3xl">
                Comment suivre le statut de ma commande?
              </Heading>
              <p>
                Une fois votre commande expédiée, vous recevrez une notification
                par courriel qui comprendra un numéro de suivi que vous pourrez
                utiliser pour vérifier son statut. Veuillez prévoir{' '}
                <strong>48 heures</strong> pour que les informations de suivi
                soient disponibles.
              </p>
              <br />
              <p>
                Si vous n'avez pas reçu votre commande{' '}
                <strong>
                  dans les 14 jours suivant la réception de votre courriel de
                  confirmation d'expédition
                </strong>
                , veuillez contacter{' '}
                <UnderlinedButton to="/contact">
                  Michel Pellerin
                </UnderlinedButton>{' '}
                en précisant votre nom et numéro de commande, afin qu'il puisse
                le vérifier pour vous.
              </p>
            </div>
          </section>

          <div className="my-24 h-[1px] w-full bg-gray-400" />

          <section className="max-md:block grid grid-cols-[200px_1fr] gap-20">
            <Heading level={2} className="!mt-0">
              Retours
            </Heading>

            <div>
              <p className="!mt-5">
                Toute commande est{' '}
                <strong>échangeable mais non remboursable</strong>. Pour tout
                échange, veuillez d'abord contacter{' '}
                <UnderlinedButton to="/contact">
                  Michel Pellerin
                </UnderlinedButton>{' '}
                .
              </p>
              <br />
              <p>
                Vous pouvez échanger tout article acheté{' '}
                <strong>dans les 14 jours suivant la date de livraison</strong>.
                Assurez-vous que l'article que vous retournez est dans son état
                d'origine.
              </p>
              <br />
              <p>
                Vous pouvez retourner votre colis à vos frais en utilisant
                n'importe quel service postal. Cependant, le colis restant sous
                votre responsabilité jusqu'à son arrivée, pensez à conserver une
                preuve d'expédition.
              </p>
            </div>
          </section>
        </div>
      </article>
    </Layout>
  );
}
